const item = document.querySelectorAll('.js-theses-item');

function thesesInViewInit() {
    if (item.length > 0) {
        item.forEach((element, idx) => {
            const observer = new IntersectionObserver(
                (entries) => {
                    entries.forEach((entry) => {
                        if (!entry.isIntersecting) {
                            entry.target.classList.remove('is-in-view');
                            if(idx === 0) {
                              document.querySelector('.history').classList.remove('gray-color');  
                            }
                            
                        } else {
                            entry.target.classList.add('is-in-view');
                            if(idx === 0) {
                              document.querySelector('.history').classList.add('gray-color');  
                            }
                        }
                    });
                },
                {
                    rootMargin: '-50% 0% -50% 0%',
                    threshold: 0,
                },
            );
            observer.observe(element);
        });
    }


    // custom cursor
    if(item.length && window.innerWidth > 1100) {
        const slider = document.querySelector('.theses__slider');

        document.querySelector('body').addEventListener('mousemove', function(event) {
            let cX = event.pageX;
            let cY = event.pageY;



            slider.style.cssText += `
                top: ${cY - slider.offsetHeight / 2}px;
                left: ${cX + 100}px;
            `
        });

        item.forEach((itm) => {
            itm.addEventListener('mousemove', () => {
                slider.classList.add('active');
        })

        itm.addEventListener('mouseleave', () => {
                slider.classList.remove('active');
            })
        });
    }
}

function thesesInit() {
    thesesInViewInit();
}

function init() {
    thesesInit();
}

const _module = { init };

export default _module;
