import './webpack-imports';
import { applyPolyfills } from './polyfills';
import gsap from 'gsap';
import ScrollTrigger from 'gsap/ScrollTrigger';

// Inits
import scroll from './inits/scroll';
import mobile from './inits/mobile';
import lazy from './inits/lazy';
import video from './inits/video';
import headerTheme from './inits/header-theme';
import input from './inits/input';
import form from './inits/form';
import modal from './inits/modal';
import modalForm from './inits/modal-form';
import back from './inits/back';
import collapse from './inits/collapse';
import newsSlider from './inits/news-slider';
import select from './inits/select';
import catalogSlider from './inits/catalog-slider';
import thesesSlider from './inits/theses-slider';

// Animations
import introAnimation from './animations/intro-animation';
import historyAnimation from './animations/history-animation';
import thesesAnimation from './animations/theses-animation';
import geoAnimation from './animations/geo-animation';
import marqueeAnimation from './animations/marquee-animation';
import aboutAnimation from './animations/about-animation';
import partnersAnimation from './animations/partners-animation';
import valuesAnimation from './animations/values-animation';
import catalogDetailAnimation from './animations/catalog-detail-animation';

document.documentElement.classList.add('js-ready');

applyPolyfills().then(() => {
    window.scrollTo(0, 0);
    gsap.registerPlugin(ScrollTrigger);
    gsap.config({
        force3D: false,
        nullTargetWarn: false,
    });

    // Inits
    scroll.init();
    mobile.init();
    lazy.init();
    video.init();
    headerTheme.init();
    modal.init();
    modalForm.init();
    input.init();
    form.init();
    back.init();
    collapse.init();
    newsSlider.init();
    select.init();
    catalogSlider.init();
    thesesSlider.init();

    // Animations
    introAnimation.init();
    historyAnimation.init();
    thesesAnimation.init();
    geoAnimation.init();
    marqueeAnimation.init();
    aboutAnimation.init();
    partnersAnimation.init();
    valuesAnimation.init();
    catalogDetailAnimation.init();

    // Inits
    scroll.refresh();
    window.dispatchEvent(new Event('resize'));
});
