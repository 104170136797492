import gsap from 'gsap';

const count = document.querySelector('.js-about-count-item');
const content = document.querySelector('.js-about-content');
const list = document.querySelector('.js-about-list');
const item = document.querySelectorAll('.js-about-item');

function aboutContentStickyInit() {
    if (content && item.length > 0) {
        gsap.to(content, {
            scrollTrigger: {
                trigger: content,
                start: '0% 0%',
                end: () => '+=' + (content.scrollHeight - item[item.length - 1].scrollHeight),
                pin: true,
                pinSpacing: true,
                invalidateOnRefresh: true,
            },
        });
    }
}

function aboutListStickyInit() {
    if (list && item.length > 0) {
        gsap.to(list, {
            scrollTrigger: {
                trigger: list,
                start: '0% 0%',
                end: () => '+=' + (list.scrollHeight - item[item.length - 1].scrollHeight),
                scrub: 0,
                invalidateOnRefresh: true,
            },
            y: () => -(list.scrollHeight - item[item.length - 1].scrollHeight),
            ease: 'none',
        });
    }
}

function aboutItemIsInViewInit() {
    if (count && item.length > 0) {
        item.forEach((element) => {
            const observer = new IntersectionObserver(
                (entries) => {
                    entries.forEach((entry) => {
                        if (!entry.isIntersecting) {
                            entry.target.classList.remove('is-in-view');
                        } else {
                            entry.target.classList.add('is-in-view');
                            let num = entry.target.getAttribute('data-count');
                            count.textContent = num;
                        }
                    });
                },
                {
                    rootMargin: '-15% 0% -85% 0%',
                    threshold: 0,
                },
            );
            observer.observe(element);
        });
    }
}

function init() {
    aboutContentStickyInit();
    aboutListStickyInit();
    aboutItemIsInViewInit();
}

const _module = { init };

export default _module;
