const header = document.querySelector('.js-header');
const section = document.querySelectorAll('.js-section');

function headerThemeInit() {
    if (section.length > 0) {
        section.forEach((element) => {
            if (header) {
                const observer = new IntersectionObserver(
                    (entries) => {
                        entries.forEach((entry) => {
                            if (entry.isIntersecting) {
                                if (entry.target.hasAttribute('data-theme')) {
                                    let theme = entry.target.getAttribute('data-theme');
                                    if (theme) {
                                        header.setAttribute('data-theme', theme);
                                    }
                                } else {
                                    header.removeAttribute('data-theme');
                                }
                            }
                        });
                    },
                    {
                        rootMargin: '0% 0% -100% 0%',
                        threshold: 0,
                    },
                );
                observer.observe(element);
            }
        });
    }
}

function headerDisabledInit() {
    if (section.length > 0) {
        section.forEach((element) => {
            if (header) {
                const observer = new IntersectionObserver(
                    (entries) => {
                        entries.forEach((entry) => {
                            if (entry.isIntersecting) {
                                if (entry.target.hasAttribute('data-disabled')) {
                                    let theme = entry.target.getAttribute('data-disabled');
                                    if (theme) {
                                        header.setAttribute('data-disabled', theme);
                                    }
                                } else {
                                    header.removeAttribute('data-disabled');
                                }
                            }
                        });
                    },
                    {
                        rootMargin: '0% 0% -100% 0%',
                        threshold: 0,
                    },
                );
                observer.observe(element);
            }
        });
    }
}

function init() {
    headerThemeInit();
    headerDisabledInit();
}

const _module = { init };

export default _module;
