import gsap from 'gsap';

const inner = document.querySelector('.s-geo .sg-anim');

function geoStickyInit() {
    if (inner && window.innerWidth >= 768) {
        // gsap.set(inner, {
        //     y: () => -window.innerHeight,
        // });

        const line = inner.querySelector('.sga-line') as HTMLElement | null;
        const lineWidth = line?.offsetWidth || 0;


        const animation = gsap.timeline({
            scrollTrigger: {
                trigger: inner,
                start: '0% 0%',
                end: () => '+=' + window.innerHeight,
                // end: () => '+=200%',
                scrub: 1.5,
                pin: true,
                pinSpacing: true,
                invalidateOnRefresh: true,
                onLeave: () => inner.classList.add('is-in-view'),
            },
        });

        animation
        .to(inner.querySelector('.sga-line'), {
            x: `-${lineWidth - window.innerWidth}px`
        })

        const animation2 = gsap.timeline({
            scrollTrigger: {
                trigger: inner,
                start: '0% 0%',
                end: () => '+=' + window.innerHeight,
                // end: () => '+=200%',
                scrub: 2,
                pin: true,
                pinSpacing: true,
                invalidateOnRefresh: true,
                onLeave: () => inner.classList.add('is-in-view'),
            },
        });

        animation2.to(inner.querySelector('.sg-cities'), {
            x: `-${lineWidth - window.innerWidth}px`
        });
    }
}

function geoInit() {
    // geoIsInViewInit();
    geoStickyInit();
}

function init() {
    geoInit();
}

const _module = { init };

export default _module;
