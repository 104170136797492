import Bouncer from 'formbouncerjs';

function formInit() {
    const validate = new Bouncer('.js-form', {
        fieldClass: 'is-error',
        errorClass: 'input__error',
        patterns: {
            email: /([0-9a-zA-Z]+@[0-9a-zA-z]+\.[0-9a-zA-Z]+)/,
            tel: /(\+7\s\(\d{3,3}\)\s\d{3,3}\-\d{2,2}\-\d{2,2})/,
        },
        messages: {
            missingValue: {
                
                default: 'Обязательное поле',
            },
            patternMismatch: {
                email: 'Введите корректный адрес электронной почты',
                tel: 'Введите корректный номер телефона',
            },
        },
    });
}

function init() {
    formInit();
}

const _module = { init };

export default _module;

