const back = document.querySelectorAll('.js-back');

function backInit() {
    if (back.length > 0) {
        back.forEach((element) => {
            element.addEventListener('click', () => {
                history.back();
            });
        });
    }
}

function init() {
    backInit();
}

const _module = { init };

export default _module;
