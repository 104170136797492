const open = document.querySelectorAll('.js-modal-form-open');
const close = document.querySelectorAll('.js-modal-form-close');

function modalOpen() {
    if (open.length > 0) {
        open.forEach((element) => {
            element.addEventListener('click', () => {
                let link = element.getAttribute('data-modal-open');
                let modal = document.querySelector('#' + link);
                if (modal) {
                    modal.classList.add('is-active');
                }
            });
        });
    }
}

function modalClose() {
    if (close.length > 0) {
        close.forEach((element) => {
            element.addEventListener('click', () => {
                let link = element.getAttribute('data-modal-close');
                let modal = document.querySelector('#' + link);
                if (modal) {
                    modal.classList.remove('is-active');
                }
            });
        });
    }
}

function modalInit() {
    modalOpen();
    modalClose();
}

function init() {
    modalInit();
}

const _module = { init };

export default _module;
