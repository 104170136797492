import gsap from 'gsap';

const content = document.querySelectorAll('.js-intro-content');
const inner = document.querySelectorAll('.js-intro-inner');

function introContentStickyInit() {
    if (content.length > 0) {
        content.forEach((element) => {
            gsap.to(element, {
                scrollTrigger: {
                    trigger: element,
                    start: '100% 100%',
                    end: () => '+=' + window.innerHeight,
                    scrub: 0,
                    invalidateOnRefresh: true,
                },
                y: () => window.innerHeight,
                ease: 'none',
            });
        });
    }
}

function introInnerStickyInit() {
    if (inner.length > 1) {
        for (let index = 1; index < inner.length; index++) {
            let element = inner[index];
            gsap.set(element, {
                y: () => -window.innerHeight,
            });
            gsap.to(element, {
                scrollTrigger: {
                    trigger: element,
                    start: '0% 0%',
                    end: () => '+=' + window.innerHeight,
                    scrub: 0,
                    invalidateOnRefresh: true,
                },
                y: 0,
                ease: 'none',
            });
        }
    }
}

function introInnerInViewInit() {
    if (inner.length > 0) {
        inner.forEach((element) => {
            const observer = new IntersectionObserver(
                (entries) => {
                    entries.forEach((entry) => {
                        if (entry.isIntersecting) {
                            entry.target.classList.add('is-in-view');
                            observer.unobserve(entry.target);
                        }
                    });
                },
                {
                    rootMargin: '-25% 0% -25% 0%',
                    threshold: 0,
                },
            );
            observer.observe(element);
        });
    }
}

function introInit() {
    introContentStickyInit();
    introInnerStickyInit();
    introInnerInViewInit();
}

function init() {
    introInit();
}

const _module = { init };

export default _module;
