const select = document.querySelectorAll('.js-select');

function selectInInit() {
    if (select.length > 0) {
        select.forEach((element) => {
            let top = element.querySelector('.js-select-top');
            let current = element.querySelector('.js-select-current');
            let bottom = element.querySelector('.js-select-bottom');
            let links = element.querySelectorAll('.js-select-link');
            if (top) {
                top.addEventListener('click', (e) => {
                    e.stopPropagation();
                    if (top) {
                        top.classList.toggle('is-active');
                    }
                    if (bottom) {
                        bottom.classList.toggle('is-active');
                    }
                });
            }
            if (links.length > 0) {
                links.forEach((link) => {
                    link.addEventListener('click', (e) => {
                        links.forEach((link) => {
                            link.classList.remove('is-active');
                        });
                        link.classList.add('is-active');
                        if (current) {
                            current.textContent = link.textContent;
                        }
                    });
                });
            }
        });
    }
}

function selectOutInit() {
    document.addEventListener('click', (e) => {
        if (e.target.className != 'js-select') {
            if (select.length > 0) {
                select.forEach((element) => {
                    let top = element.querySelector('.js-select-top');
                    let bottom = element.querySelector('.js-select-bottom');
                    if (top && top.classList.contains('is-active')) {
                        top.classList.remove('is-active');
                    }
                    if (bottom && bottom.classList.contains('is-active')) {
                        bottom.classList.remove('is-active');
                    }
                });
            }
        }
    });
}

function selectInit() {
    selectOutInit();
    selectInInit();
}

function init() {
    selectInit();
}

const _module = { init };

export default _module;
