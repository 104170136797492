import scroll from './scroll';

const collapse = document.querySelectorAll('.js-collapse');

function collapseHeight(bottom) {
    if (bottom) {
        bottom.setAttribute('style', 'max-height: 0px');
        window.addEventListener('resize', bottom.setAttribute('style', 'max-height: 0px'));
    }
}

function collapseActiveHeight(bottom) {
    if (bottom) {
        bottom.setAttribute('style', 'max-height: ' + bottom.scrollHeight + 'px');
        window.addEventListener('resize', bottom.setAttribute('style', 'max-height: ' + bottom.scrollHeight + 'px'));
    }
}

function collapseToggle(top, bottom) {
    if (!top.classList.contains('is-active')) {
        top.classList.add('is-active');
        collapseActiveHeight(bottom);
        scroll.update();
    } else {
        top.classList.remove('is-active');
        collapseHeight(bottom);
        scroll.update();
    }
}

function collapseInit() {
    if (collapse.length > 0) {
        collapse.forEach((element) => {
            let top = element.querySelector('.js-collapse-top');
            let bottom = element.querySelector('.js-collapse-bottom');
            if (top) {
                top.addEventListener('click', () => {
                    collapseToggle(top, bottom);
                });
            }
        });
    }
}

function init() {
    collapseInit();
}

const _module = { init };

export default _module;
