import Glide, { Controls, Breakpoints, Swipe } from '@glidejs/glide/dist/glide.modular.esm';

const slider = document.querySelector('.js-catalog-slider');

function newsSliderInit() {
    if (slider) {
        new Glide(slider, {
            animationDuration: 600,
            animationTimingFunc: 'cubic-bezier(0.38, 0.005, 0.215, 1)',
            rewind: false,
            perView: 4,
            gap: 0,
            breakpoints: {
                1024: {
                    perView: 2,
                },
                567: {
                    perView: 1,
                },
            },
        }).mount({ Controls, Breakpoints, Swipe });
    }
}

function init() {
    newsSliderInit();
}

const _module = { init };

export default _module;
