import Scrollbar, { ScrollbarPlugin } from 'smooth-scrollbar';
import ScrollTrigger from 'gsap/ScrollTrigger';
// import HeaderAnimation from './header-scroll'

class AnchorPlugin extends ScrollbarPlugin {
    static pluginName = 'anchor';

    onHashChange = () => {
        this.jumpToHash(window.location.hash);
    };

    onClick = (event) => {
        const { target } = event;

        if (target.tagName !== 'A') {
            return;
        }

        const hash = target.getAttribute('href');

        if (!hash || hash.charAt(0) !== '#') {
            return;
        }

        this.jumpToHash(hash);
    };

    jumpToHash = (hash) => {
        const { scrollbar } = this;

        if (!hash) {
            return;
        }

        // reset scrollTop
        scrollbar.containerEl.scrollTop = 0;

        setTimeout(() => {
            scrollbar.scrollIntoView(document.querySelector(hash));
        }, 100);
    };

    onInit() {
        this.jumpToHash(window.location.hash);

        window.addEventListener('hashchange', this.onHashChange);

        this.scrollbar.contentEl.addEventListener('click', this.onClick);
    }

    onDestory() {
        window.removeEventListener('hashchange', this.onHashChange);

        this.scrollbar.contentEl.removeEventListener('click', this.onClick);
    }
}
class DisableScrollPlugin extends ScrollbarPlugin {
    static pluginName = 'disableScroll';

    static defaultOptions = {
        direction: '',
    };

    transformDelta(delta) {
        
        if (this.options.direction) {
            delta[this.options.direction] = 0;
        }
        scrollDelta = delta.y
        return { ...delta };
    }
}
class updatePlugin extends ScrollbarPlugin {
  static pluginName = 'updatePlugin';

  onUpdate() {
    scrollTopValue = window.scrollWrapper.offset.y;
    // HeaderAnimation.headerScroll(scrollTopValue, scrollDelta);
    document.querySelector('.theses__slider').classList.remove('active');
  }
}

Scrollbar.use(AnchorPlugin);
Scrollbar.use(DisableScrollPlugin);
Scrollbar.use(updatePlugin);


const scrollWrapper = document.querySelector('.js-scroll-wrapper');
const scrollCustom = document.querySelectorAll('.js-scroll-custom');

let scrollTopValue: number = 0;
let scrollDelta: number = 1;

function scrollTo() {
    document.querySelectorAll('[scroll-to]').forEach((btn) => {
        btn.addEventListener('click', function(e) { 
            e.preventDefault();

            const scrollPoint = btn.getAttribute('scroll-to'); 
            const st = document.querySelector(`${scrollPoint}`).offsetTop;

            window.scrollWrapper.scrollTo(0, st, 1000); 


            document.querySelector('.js-header-burger').classList.remove('is-active');
            document.querySelector('.js-modal-menu').classList.remove('is-active');
            document.querySelector('.js-header').classList.remove('is-active');
            document.querySelector('.js-header').classList.remove('is-menu-open');
        });
    })

}

function scrollWrapperInit() {
    if (scrollWrapper) {
        document.documentElement.classList.add('is-smooth');
        window.scrollWrapper = Scrollbar.init(scrollWrapper, {
            plugins: {
                disableScroll: {
                    direction: 'x',
                },
            },
            renderByPixels: false,
        });
        window.scrollWrapper.addListener(ScrollTrigger.update);

        ScrollTrigger.scrollerProxy(scrollWrapper, {
            scrollTop(value) {
                if (arguments.length) {
                    window.scrollWrapper.scrollTop = value;
                }
                return window.scrollWrapper.scrollTop;
            },
            scrollLeft(value) {
                if (arguments.length) {
                    window.scrollWrapper.scrollLeft = value;
                }
                return window.scrollWrapper.scrollLeft;
            },
            getBoundingClientRect() {
                return { top: 0, left: 0, width: window.innerWidth, height: window.innerHeight };
            },
            pinType: 'transform',
        });
        ScrollTrigger.defaults({
            scroller: scrollWrapper,
            // onUpdate: (self) => {
            //     // console.log(self);
            //     delta = self.direction;
            // }
        });
        window.addEventListener('beforeunload', () => {
            history.replaceState({ data: window.scrollWrapper.scrollTop }, '');
        });
        if (history.state) {
            setTimeout(() => {
                window.scrollWrapper.setPosition(0, history.state.data);
            }, 150);
        }

        // set scroll to 0
        window.scrollWrapper.scrollTo(0, 0, 1000); 
    }
}

function scrollCustomInit() {
    if (scrollCustom.length > 0) {
        scrollCustom.forEach((element) => {
            document.documentElement.classList.add('is-smooth');
            window.scrollCustom = Scrollbar.init(element, {
                renderByPixels: false,
            });
        });
    }
}

function scrollInit() {
    scrollWrapperInit();
    scrollCustomInit();
    scrollTo();
}

function init() {
    scrollInit();
}

function refresh() {
    ScrollTrigger.addEventListener('refresh', () => window.scrollWrapper.update());
    ScrollTrigger.refresh();
    window.scrollWrapper.update();
}

function update() {
    window.scrollWrapper.update();
    ScrollTrigger.refresh();
    window.dispatchEvent(new Event('resize'));
}



const _module = { init, refresh, update };

export default _module;
