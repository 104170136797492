const video = document.querySelectorAll('.js-video');

function videoInit() {
    if (video.length > 0) {
        video.forEach((element) => {
            const observer = new IntersectionObserver(
                (entries) => {
                    entries.forEach((entry) => {
                        if (!entry.target.paused) {
                            entry.target.pause();
                        } else if (entry.target.currentTime != 0) {
                            entry.target.play();
                        }
                    });
                },
                {
                    rootMargin: '0% 0% 0% 0%',
                    threshold: 0,
                },
            );
            observer.observe(element);
        });
    }
}

function init() {
    videoInit();
}

const _module = { init };

export default _module;

