import Swiper from 'swiper';

const slider = document.querySelector('.js-theses-slider');
const hoverItems = document.querySelectorAll('.theses .theses__item');

function newsSliderInit() {
    if (slider) {
        const thesesSlider = new Swiper(slider, {
            direction: 'vertical',
            allowTouchMove: false,
            speed: 1000,
            slidesPerView: 1,
            spaceBetween: 0,
        });

        hoverItems.forEach((itm, idx) => {
            itm.addEventListener('mouseover', () => {
                thesesSlider.slideTo(idx);
            });
        });
    }
}

function init() {
    newsSliderInit();
}

const _module = { init };

export default _module;
