import gsap from 'gsap';
import ScrollTrigger from 'gsap/ScrollTrigger';

const content = document.querySelector('.js-history-content');
const left = document.querySelector('.js-history-left');
const heading = document.querySelector('.js-history-heading');
const award = document.querySelector('.js-history-award');
const right = document.querySelector('.js-history-right');

function historyLeftInit() {
    if (left && right) {
        gsap.to(left, {
            scrollTrigger: {
                trigger: left,
                start: '100% 100%',
                end: () => '+=' + (right.scrollHeight - left.scrollHeight),
                scrub: 0,
                invalidateOnRefresh: true,
            },
            y: () => right.scrollHeight - left.scrollHeight,
            ease: 'none',
        });
    }
}

function historyInit() {
    ScrollTrigger.matchMedia({
        '(min-width: 1025px)': function () {
            if (content) {
                let tl = gsap.timeline({
                    scrollTrigger: {
                        trigger: content,
                        start: '0% 0%',
                        end: () => '+=' + window.innerHeight,
                        scrub: 0,
                        pin: true,
                        pinSpacing: true,
                        invalidateOnRefresh: true,
                        onLeave: historyLeftInit,
                    },
                })
                if (heading) {
                    tl.to(
                        heading,
                        {
                            yPercent: 10,
                            scale: 0.29,
                            ease: 'none',
                        },
                        'a-1',
                    );
                }
                if (award) {
                    tl.to(
                        award,
                        {
                            xPercent: -200,
                            ease: 'none',
                        },
                        'a-1',
                    );
                }
                if (right) {
                    tl.to(
                        right,
                        {
                            xPercent: -66.666,
                            ease: 'none',
                        },
                        'a-1',
                    );
                }
            }
        },
    });
}

function init() {
    historyInit();
}

const _module = { init };

export default _module;
