function mobileVh() {
    if (
        /Android|webOS|iPhone|iPad|iPod|BlackBerry|BB|PlayBook|IEMobile|Windows Phone|Kindle|Silk|Opera Mini/i.test(
            navigator.userAgent,
        )
    ) {
        document.documentElement.classList.add('is-touch');
        const vh = window.innerHeight * 0.01;
        document.documentElement.style.setProperty('--vh', `${vh}px`);
    }
}

function mobileInit() {
    mobileVh();
    window.addEventListener('resize', mobileVh);
}

function init() {
    mobileInit();
}

const _module = { init };

export default _module;
