import gsap from 'gsap';

const marquee = document.querySelectorAll('.js-marquee');

function marqueeInit() {
    if (marquee.length > 0) {
        marquee.forEach((element) => {
            let list = element.querySelector('.js-marquee-list');
            if (list) {
                list.after(list.cloneNode(true));
            }
            let listArray = element.querySelectorAll('.js-marquee-list');
            if (listArray.length > 0) {
                listArray.forEach((newList) => {
                    const anim = gsap.to(newList, {
                        repeat: -1,
                        paused: true,
                        x: -newList.scrollWidth,
                        duration: newList.scrollWidth / 100,
                        ease: 'none',
                    });
                    const observer = new IntersectionObserver(
                        (entries) => {
                            entries.forEach((entry) => {
                                if (!entry.isIntersecting) {
                                    anim.pause();
                                } else {
                                    anim.play();
                                }
                            });
                        },
                        {
                            rootMargin: '0% 0% 0% 0%',
                            threshold: 0,
                        },
                    );
                    observer.observe(element);
                });
            }
        });
    }
}

function init() {
    marqueeInit();
}

const _module = { init };

export default _module;
