import gsap from 'gsap';
import ScrollTrigger from 'gsap/ScrollTrigger';

const right = document.querySelector('.js-catalog-detail-right');
const end = document.querySelector('.js-catalog-detail-right-end');
const section = document.querySelectorAll('.js-section-anchor');
const link = document.querySelectorAll('.js-catalog-detail-link');

function catalogDetailStickyInit() {
    ScrollTrigger.matchMedia({
        '(min-width: 1025px)': function () {
            if (right && end) {
                gsap.to(right, {
                    scrollTrigger: {
                        trigger: right,
                        start: '0% 0%',
                        endTrigger: end,
                        pin: true,
                        pinSpacing: false,
                        invalidateOnRefresh: true,
                    },
                    ease: 'none',
                });
            }
        },
    });
}

function catalogDetailLinksInit() {
    if (section.length > 0) {
        section.forEach((element) => {
            const observer = new IntersectionObserver(
                (entries) => {
                    entries.forEach((entry) => {
                        if (entry.isIntersecting) {
                            if (link.length > 0) {
                                link.forEach((element) => {
                                    element.classList.remove('is-active');
                                    if (element.getAttribute('href') === '#' + entry.target.getAttribute('id')) {
                                        element.classList.add('is-active');
                                    }
                                });
                            }
                        }
                    });
                },
                {
                    rootMargin: '-50% 0% -50% 0%',
                    threshold: 0,
                },
            );
            observer.observe(element);
        });
    }
}

function init() {
    catalogDetailStickyInit();
    catalogDetailLinksInit();
}

const _module = { init };

export default _module;
