import lazy from '../inits/lazy';
import gsap from 'gsap';

const marquee = document.querySelectorAll('.js-partners-marquee');

function partnersMarqueeInit() {
    if (marquee.length > 0) {
        for (let index = 0; index < marquee.length; index++) {
            let element = marquee[index];
            let list = element.querySelector('.js-partners-marquee-list');
            if (list) {
                list.after(list.cloneNode(true));
                lazy.update();
            }
            if (index % 2 === 0) {
                let element = marquee[index];
                let listArray = element.querySelectorAll('.js-partners-marquee-list');
                if (listArray.length > 0) {
                    listArray.forEach((newList) => {
                        const anim = gsap.to(newList, {
                            repeat: -1,
                            paused: true,
                            x: -newList.scrollWidth,
                            duration: newList.scrollWidth / 30,
                            ease: 'none',
                        });
                        const observer = new IntersectionObserver(
                            (entries) => {
                                entries.forEach((entry) => {
                                    if (!entry.isIntersecting) {
                                        anim.pause();
                                    } else {
                                        anim.play();
                                    }
                                });
                            },
                            {
                                rootMargin: '0% 0% 0% 0%',
                                threshold: 0,
                            },
                        );
                        observer.observe(element);
                    });
                }
            } else {
                let element = marquee[index];
                let listArray = element.querySelectorAll('.js-partners-marquee-list');
                if (listArray.length > 0) {
                    listArray.forEach((newList) => {
                        const anim = gsap.to(newList, {
                            repeat: -1,
                            paused: true,
                            x: newList.scrollWidth,
                            duration: newList.scrollWidth / 30,
                            ease: 'none',
                        });
                        const observer = new IntersectionObserver(
                            (entries) => {
                                entries.forEach((entry) => {
                                    if (!entry.isIntersecting) {
                                        anim.pause();
                                    } else {
                                        anim.play();
                                    }
                                });
                            },
                            {
                                rootMargin: '0% 0% 0% 0%',
                                threshold: 0,
                            },
                        );
                        observer.observe(element);
                    });
                }
            }
        }
    }
}

function init() {
    partnersMarqueeInit();
}

const _module = { init };

export default _module;
