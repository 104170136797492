import gsap from 'gsap';
import ScrollTrigger from 'gsap/ScrollTrigger';

const left = document.querySelector('.js-values-left');
const right = document.querySelector('.js-values-right');

function valuesLeftInit() {
    ScrollTrigger.matchMedia({
        '(min-width: 1025px)': function () {
            if (left && right) {
                gsap.to(left, {
                    scrollTrigger: {
                        trigger: left,
                        start: '0% 0%',
                        end: () => '+=' + (right.scrollHeight - left.scrollHeight),
                        scrub: 0,
                        invalidateOnRefresh: true,
                    },
                    y: () => right.scrollHeight - left.scrollHeight,
                    ease: 'none',
                });
            }
        },
    });
}

function valuesInit() {
    valuesLeftInit();
}

function init() {
    valuesInit();
}

const _module = { init };

export default _module;
