import LazyLoad from 'vanilla-lazyload';

const lazy = document.querySelector(':not(.js-scroll-wrapper) .js-lazy');
const wrapperLazy = document.querySelector('.js-scroll-wrapper .js-lazy');
const newsDetailLazy = document.querySelectorAll('.news-detail__middle img');

function lazyInit() {
    if (lazy) {
        new LazyLoad({
            elements_selector: ':not(.js-scroll-wrapper) .js-lazy',
            threshold: 1000,
        });
    }
    if (wrapperLazy) {
        window.lazy = new LazyLoad({
            container: document.querySelector('.js-scroll-wrapper'),
            elements_selector: '.js-scroll-wrapper .js-lazy',
            threshold: 1000,
        });
    }
    if (newsDetailLazy.length > 0) {
        newsDetailLazy.forEach((element) => {
            let src = element.getAttribute('src');
            if (src) {
                element.removeAttribute('src');
                element.setAttribute('data-src', src);
            }
        });
        new LazyLoad({
            container: document.querySelector('.js-scroll-wrapper'),
            elements_selector: '.news-detail__middle img',
            threshold: 1000,
        });
    }
}

function init() {
    lazyInit();
}

function update() {
    if (window.lazy) {
        window.lazy.update();
    }
}

const _module = { init, update };

export default _module;
