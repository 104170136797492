import IMask from 'imask';

const input = document.querySelectorAll('.js-input');
const phone = document.querySelectorAll('.js-input-phone');
const file = document.querySelectorAll('.js-file');

function inputFilledInit() {
    if (input.length > 0) {
        input.forEach((element) => {
            element.addEventListener('blur', () => {
                if (element.value === '') {
                    element.classList.remove('is-filled');
                } else {
                    element.classList.add('is-filled');
                }
            });
        });
    }
}

function ipnutPhoneMaskInit() {
    if (phone.length > 0) {
        phone.forEach((element) => {
            const mask = IMask(element, {
                mask: '+{7} (000) 000-00-00',
            });
        });
    }
}

function ipnutFileInit() {
    if (file.length > 0) {
        file.forEach((element) => {
            let input = element.querySelector('.js-input-file');
            if (input) {
                input.addEventListener('change', (e) => {
                    let [file] = e.target.files;
                    let { name: fileName } = file;
                    let s = `${fileName}`;
                    let name = element.querySelector('.js-input-file-name');
                    if (name) {
                        name.textContent = s;
                    }
                });
            }
        });
    }
}

function inputInit() {
    inputFilledInit();
    ipnutPhoneMaskInit();
    ipnutFileInit();
}

function init() {
    inputInit();
}

const _module = { init };

export default _module;
