const header = document.querySelector('.js-header');
const burger = document.querySelector('.js-header-burger');
const link = document.querySelectorAll('.js-header-menu-link');
const city = document.querySelector('.js-header-info-city');
const modalMenu = document.querySelector('.js-modal-menu');
const modalMenuBackdrop = document.querySelector('.js-modal-menu-backdrop');
const modalCity = document.querySelector('.js-modal-city');
const modalCityBackdrop = document.querySelector('.js-modal-city-backdrop');

function headerInit() {
    if (header) {
        header.classList.add('is-active');
    }
}

function headerDestroy() {
    if (header) {
        header.classList.remove('is-active');
    }
}

function menuInit() {
    if (header) {
        header.classList.add('is-menu-open');
    }
}

function menuDestroy() {
    if (header) {
        header.classList.remove('is-menu-open');
    }
}

function modalMenuInit() {
    if (modalMenu) {
        modalMenu.classList.add('is-active');
    }
}

function modalMenuDestroy() {
    if (modalMenu) {
        modalMenu.classList.remove('is-active');
    }
}

function modalCityInit() {
    if (modalCity) {
        modalCity.classList.add('is-active');
    }
}

function modalCityDestroy() {
    if (modalCity) {
        modalCity.classList.remove('is-active');
    }
}

function closeMenuInit() {
    headerDestroy();
    modalMenuDestroy();
    menuDestroy();
    if (burger) {
        burger.classList.remove('is-active');
    }
    if (link) {
        link.forEach((ln) => {
            ln.classList.remove('is-active');
        })
        
    }
}

function closeCityInit() {
    headerDestroy();
    modalCityDestroy();
    if (city) {
        city.classList.remove('is-active');
    }
}

function burgerInit() {
    if (burger) {
        // if (link && !link.classList.contains('is-active') && city && !city.classList.contains('is-active')) {
        if (link) {
            if (!burger.classList.contains('is-active')) {
                headerInit();
                menuInit();
                modalMenuInit();
                burger.classList.add('is-active');
            } else {
                headerDestroy();
                menuDestroy();
                modalMenuDestroy();
                closeMenuInit();
                burger.classList.remove('is-active');
            }
        }
        // if (link) {
        //     if (!burger.classList.contains('is-active')) {
        //         menuInit();
        //         burger.classList.add('is-active');
        //     } else {
        //         menuDestroy();
        //         burger.classList.remove('is-active');
        //     }
        // }
        if (city && city.classList.contains('is-active')) {
            if (!burger.classList.contains('is-active')) {
                city.classList.remove('is-active');
                modalCityDestroy();
                menuInit();
                modalMenuInit();
                burger.classList.add('is-active');
            } else {
                headerDestroy();
                menuDestroy();
                modalMenuDestroy();
                burger.classList.remove('is-active');
            }
        }
    }
}

function linkInit(this) {
    if (link) {
        // if (burger && !burger.classList.contains('is-active') && city && !city.classList.contains('is-active')) {
        if (burger && !burger.classList.contains('is-active')) {
            headerInit();
            modalMenuInit();
            menuInit();
            burger.classList.add('is-active');
            this.classList.add('is-active');
        }
        if (city && city.classList.contains('is-active')) {
            city.classList.remove('is-active');
            modalCityDestroy();
            modalMenuInit();
        }
    }
}

function cityInit() {
    if (city) {
        if (link && !link.classList.contains('is-active')) {
            if (!city.classList.contains('is-active')) {
                headerInit();
                modalCityInit();
                city.classList.add('is-active');
            } else {
                headerDestroy();
                modalCityDestroy();
                city.classList.remove('is-active');
            }
        }
        if (link && link.classList.contains('is-active')) {
            if (!city.classList.contains('is-active')) {
                link.classList.remove('is-active');
                modalMenuDestroy();
                modalCityInit();
                city.classList.add('is-active');
            } else {
                headerDestroy();
                modalCityDestroy();
                city.classList.remove('is-active');
            }
        }
    }
}

function modalInit() {
    if (burger) {
        burger.addEventListener('click', burgerInit);
    }
    if (link) {
        link.forEach((ln) => {
           ln.addEventListener('mouseenter', linkInit); 
        })
        
    }
    if (city) {
        city.addEventListener('click', cityInit);
    }
    if (modalMenuBackdrop) {
        modalMenuBackdrop.addEventListener('click', closeMenuInit);
    }
    if (modalCityBackdrop) {
        modalCityBackdrop.addEventListener('click', closeCityInit);
    }
}

function init() {
    modalInit();
}

const _module = { init };

export default _module;
